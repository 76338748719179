<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody v-if="valid_token" class="p-4">
              <CForm>
                <!-- {{ form.token }} {{ valid_token }} -->
                <h1 style="text-align: center">Reset your password</h1>

                <CInput
                  placeholder="Enter New Password"
                  type="password"
                  v-model="form.password"
                  autocomplete="new-password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Repeat password"
                  type="password"
                  v-model="form.password_confirmation"
                  autocomplete="new-password"
                  class="mb-4"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>

                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton @click="submit_reset" color="dark" block
                      >Save</CButton
                    >
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" to="/a/login" class="ml-4 px-0"
                      >Login</CButton
                    >
                    <CButton color="link" to="/a/register" class="ml-4 px-0"
                      >Register</CButton
                    >
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardBody v-else>
              <h3>The token is invalid!</h3>
              <CCol col="6" class="text-left">
                <CButton color="link" to="/a/forgot" class="px-0"
                  >Forgot password?</CButton
                >
                <CButton color="link" to="/a/login" class="ml-4 px-0"
                  >Login</CButton
                >
              </CCol>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ResetPassword",
  data() {
    return {
      loading: false,
      valid_token: false,
      form: {
        token: location.pathname.split("/")[3],
        password: "",
        password_confirmation: "",
      },
      errors: { email: [], password: [] },
    };
  },
  created() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      // const token = window.location.pathname.split("/")[3];
      const axios = require("axios");

      var vm = this;
      vm.loading = true;
      axios
        .get(`/check/token/${vm.form.token}`)
        .then(function(res) {
          // handle success

          console.log(res);
          vm.loading = false;
          vm.valid_token = res.data.valid_token;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          vm.loading = false;
        });
    },
    submit_reset() {
      const axios = require("axios");
      console.log(this.form);
      let vm = this;
      vm.loading = true;
      axios
        .post("/reset", this.form)
        .then((res) => {
          console.log(res.data);
          // localStorage.setItem("user", JSON.stringify(res.data.user));
          // localStorage.setItem("token", res.data.token);
          vm.loading = false;
          this.$router.push("/a/login");
        })
        .catch((err) => {
          console.log(err);
          vm.loading = false;
          console.log("loading: ", vm.loading);
          vm.errors = err.response.data.errors;
          console.log(vm.errors);
          Swal.fire("Oops...", err.response.data.message, "error");
        });
    },
  },
};
</script>
